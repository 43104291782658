/* My Hiragino */
@font-face {
    font-family: 'MyHiragino';
    font-weight: normal;
    src: local('Hiragino Sans'),
         local('ヒラギノ角ゴ ProN W3'),
         local('Hiragino Kaku Gothic ProN');
}
@font-face {
    font-family: 'MyHiragino';
    font-weight: bold;
    src: local('Hiragino Sans'),
         local('ヒラギノ角ゴ ProN W6'),
         local('Hiragino Kaku Gothic ProN W6');
}
@font-face {
    font-family: 'MyHiragino';
    font-weight: 900;
    src: local('Hiragino Sans'),
         local('ヒラギノ角ゴ StdN'),
         local('Hiragino Kaku Gothic StdN');
}
/* AppleSystem */
@font-face {
    font-family: 'AppleSystem';
    src: local('-apple-system'),
         local('BlinkMacSystemFont');
}

/* MySans */
@font-face {
    font-family: 'MySans';
    font-weight: 400;
    src: local('HelveticaNeue'),
         local('Helvetica Neue'),
         local('Helvetica'),
         local('Arial');
}
@font-face {
    font-family: 'MySans';
    font-weight: 700;
    src: local('HelveticaNeueBold'),
         local('HelveticaNeue-Bold'),
         local('Helvetica Neue Bold'),
         local('HelveticaBold'),
         local('Helvetica-Bold'),
         local('Helvetica Bold'),
         local('HelveticaNeue'),
         local('Helvetica Neue'),
         local('Helvetica'),
         local('Arial');
}
@font-face {
    font-family: 'MySans';
    font-weight: 900;
    src: local('HelveticaNeueBlack'),
         local('HelveticaNeue-Black'),
         local('Helvetica Neue Black'),
         local('HelveticaBlack'),
         local('Helvetica-Black'),
         local('Helvetica Black'),
         local('HelveticaNeue'),
         local('Helvetica Neue'),
         local('Helvetica'),
         local('Arial');
}
@font-face {
  font-family: 'icomoon';
  src:url('/fonts/icomoon.eot?-qz7pb2');
  src:url('/fonts/icomoon.eot?#iefix-qz7pb2') format('embedded-opentype'),
    url('/fonts/icomoon.woff?-qz7pb2') format('woff'),
    url('/fonts/icomoon.ttf?-qz7pb2') format('truetype'),
    url('/fonts/icomoon.svg?-qz7pb2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oldstandardi';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/oldstandard-italic-webfont.woff') format('woff'),
}

@font-face {
 font-family: 'migmix2pb';/*フォント名 任意*/
 src: url('/fonts/migmix-2p-regular.ttf');/*for ie*/
}

@font-face {
 font-family: 'genshingothicmedium';/*フォント名 任意*/
 src: url('/fonts/GenShinGothic-Medium.ttf');/*for ie*/
}

@font-face {
 font-family: 'genshingothiclight';/*フォント名 任意*/
 src: url('/fonts/GenShinGothic-Light.ttf');/*for ie*/
}

@font-face {
    font-family: 'texgyreheros';
    src: url('/fonts/texgyreheros-regular-webfont.woff2') format('woff2'),
         url('/fonts/texgyreheros-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'texgyreherosb';
    src: url('/fonts/texgyreheros-bold-webfont.woff2') format('woff2'),
         url('/fonts/texgyreheros-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'texgyreadventorb';
  src: url('/fonts/texgyreadventor-bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/texgyreadventor-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/texgyreadventor-bold.woff') format('woff'), /* Modern Browsers */
       url('/fonts/texgyreadventor-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/fonts/texgyreadventor-bold.svg#410bc7c0a96055f314865205a0a58b43') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'texgyreadventor';
  src: url('/fonts/texgyreadventor-regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/texgyreadventor-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/texgyreadventor-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/texgyreadventor-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/fonts/texgyreadventor-regular.svg#eb38d5b06bf3b7ce91bf1ab81bd95287') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
    font-family: 'amaticb';
    src: url('/fonts/amatic-bold-webfont.woff2') format('woff2'),
         url('/fonts/amatic-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'stockholm';
    src: url('/fonts/stockholm-webfont.woff2') format('woff2'),
         url('/fonts/stockholm-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'stilltimei';
    src: url('/fonts/stilltime-webfont.woff2') format('woff2'),
         url('/fonts/stilltime-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* YakuHanJP */
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 100;
    src: url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Thin.eot');
    src: local('☺︎'),
         url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Thin.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Thin.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 200;
    src: url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Light.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Light.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Light.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 300;
    src:  url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-DemiLight.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-DemiLight.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-DemiLight.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 400;
    src:  url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Regular.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Regular.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Regular.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 500;
    src:  url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Medium.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Medium.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Medium.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 700;
    src:  url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Bold.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Bold.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Bold.woff') format('woff');
}
@font-face {
    font-family: 'YakuHanJP';
    font-style: normal;
    font-weight: 900;
    src:  url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Black.eot');
    src: local('☺︎'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Black.woff2') format('woff2'),
          url('https://cdn.jsdelivr.net/yakuhanjp/1.3.1/fonts/YakuHanJP/YakuHanJP-Black.woff') format('woff');
}